<template>
  <div>
    <v-row dense>
      <v-col md="12" cols="12">
        <v-card flat dense>
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h5 class="font-weight-light">HGE TRANSACTION SECTION</h5>
            </v-toolbar-title>
            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            <strong>
              <h3 class="font-weight-light">Overall Transaction: {{ formatPrice(balance_format)
                }}</h3>
            </strong>
          </v-toolbar>
          <v-card-text>
            <v-row dense class="mt-4">
              <v-col md="3" cols="12">
                <v-select
                  class="mx-2"
                  :items="fund_option_items"
                  label="Type of Service"
                  item-value="id"
                  item-text="service_type"
                  required
                  :rules="rules.combobox_rule"
                  v-model="option"
                  dense
                  @change="reset"
                ></v-select>
              </v-col>
              <v-col md="12" cols="12">
                <payment-sections v-on:data="on_save"
                                  :particulars="particulars"
                                  :mode_of_service="mode_of_service"
                                  :fund_particulars_id="fund_particulars_id"
                                  :amount_for_member="amount_for_member"
                                  :amount_for_non_member="amount_for_non_member"
                ></payment-sections>
              </v-col>

              <v-col md="12" cols="12">
                <h2>Data</h2>
                <payments-data v-on:data="on_save"
                               :liquidations="fund_items"></payments-data>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import PaymentSections
    from '@/views/other_transaction/transaction/sporo_fund_liquidations/SporoFundLiquidationSections'
  import PaymentsData
    from '@/views/other_transaction/transaction/sporo_fund_liquidations/SporoFundLiquidationData'

  const initialState = () => {
    return {
      payment_for_data: [],

      balance_format: 0,
      fund_balance: 0,

      fund_items: [],
      option: '',
      category: '',
      reference_no: '',
      particulars: '',
      mode_of_service: '',
      fund_option_items: [],
      fund_particulars_items: [],
      fund_category_items: [],
      amount_for_member: 0,
      amount_for_non_member: 0,
      is_have_client: false,

      fund_particulars_id: 0,
    }
  }

  export default {
    components: {
      PaymentSections,
      PaymentsData,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    created() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_branch_id']),
    },
    methods: {
      ...mapActions('membersService', ['get_liquidation']),
      ...mapActions('type_of_service', ['list_of_active_type_of_service']),

      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      initialize_data() {
        this.list_of_active_type_of_service()
          .then(response => {
            this.fund_option_items = response.data
          })
          .catch(error => {
            console.log(error)
          })

        const data = new FormData()
        data.append('branch_id', this.employee_branch_id)
        this.get_liquidation(data)
          .then(response => {
            this.fund_items = response.data[0].data
            this.balance_format = response.data[0].balance
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.amount_for_member = 0
        this.amount_for_non_member = 0
        if (this.fund_option_items.length > 0) {
          var index = this.fund_option_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.option)
          if (index >= 0) {
            this.particulars = this.fund_option_items[index].service_type
            this.mode_of_service = this.fund_option_items[index].mode_of_service
            this.fund_particulars_id = this.option
            this.amount_for_member = parseFloat(this.fund_option_items[index].amount_for_member)
            this.amount_for_non_member = parseFloat(this.fund_option_items[index].amount_for_non_member)
          }
        }
      }
      ,
      on_save(value) {
        if (value) {
          Object.assign(this.$data, initialState())
          this.initialize_data()
        }
      }
    },
  }
</script>

<style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
