<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase">
            Name
          </th>
          <th class="text-center text-uppercase">
            Type
          </th>
          <th class="text-center text-uppercase">
            Date
          </th>
          <th class="text-center text-uppercase">
            Type of Service
          </th>
          <th class="text-center text-uppercase">
            Amount
          </th>
          <th class="text-center text-uppercase">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in liquidations"
          :key="item.id"
        >
          <td class="text-center">
            {{ item.member!=null?item.member.last_name +', '+item.member.first_name
            +' '+item.member.middle_name:
            item.last_name+', '+item.middle_name+' '+item.middle_name}}
          </td>
          <td class="text-center">
            {{ item.member_id!=null?'MEMBER':'NON MEMBER' }}
          </td>
          <td class="text-center">
            {{ item.date }}
          </td>
          <td class="text-center">
            {{ item.type_of_service }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.amount) }}
          </td>
          <td class="text-center">
            <v-chip
              v-if="item.deposit_slip_id!=null"
              small
              color="success"
              class="font-weight-medium"
            >
              GOOD
            </v-chip>
            <v-chip
              v-else
              small
              color="error"
              class="font-weight-medium"
            >
              PENDING
            </v-chip>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>
</template>

<script>
  import {mdiDelete} from "@mdi/js";
  import moment from "moment";

  export default {
    setup() {
      return {
        icons: {
          mdiDelete,
        },
        is_deleting: false,
      }
    },
    props: {
      liquidations: Array,
    },
    methods: {
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
  }
</script>
