<template>
  <div>
    <v-card flat dense>
      <v-form ref="form" lazy-validation>
        <v-flex xs12>
          <v-flex xs12 md3>
            <v-checkbox v-model="is_member" label="is Member?" hide-details
                        class="me-3 mt-1" @change="calculate_charges"></v-checkbox>
          </v-flex>
          <v-layout row wrap align-center class="mt-4">
            <v-flex xs12 md12 v-if="is_member">
              <v-autocomplete
                class="mx-2"
                v-model="search"
                :items="search_items"
                :loading="isLoading"
                @keyup.enter="searching($event.target.value)"
                hide-no-data
                item-text="name"
                item-value="id"
                label="Search"
                placeholder="Press Enter to Search "
                :prepend-icon="icons.mdiAccountSearchOutline"
                @change="get_search_items_info"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 md4 v-if="!is_member">
              <v-text-field
                class="mx-3"
                v-model="last_name"
                label="Last Name"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-if="!is_member">
              <v-text-field
                class="mx-3"
                v-model="first_name"
                label="First Name"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4 v-if="!is_member">
              <v-text-field
                class="mx-3"
                v-model="middle_name"
                label="Middle Name"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field v-model="date"
                            label="Date"
                            class="mx-2" required
                            type="date"
                            :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field v-model="total_amount"
                            label="Total"
                            class="mx-2" required
                            readonly></v-text-field>
            </v-flex>

            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_payments" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-layout>
        </v-flex>
      </v-form>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      is_customize_amount: Boolean,
      fund_particulars_id: Number,
      amount_for_member: Number,
      amount_for_non_member: Number,
      particulars: String,
      mode_of_service: String,
      category: String,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        date: '',
        search: '',
        search_membersss: '',
        search_items: [],
        isLoading: false,
        is_member: false,

        member_id: 0,
        payor: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        new_amount: 0,
        amount_fee: 0,
        billers_amount_fee: 0,
        processing_amount_fee: 0,
        total_amount: 0,
        account: '',
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',

        reference_no: '',
      }
    },
    watch: {
      fund_particulars_id: function () {
        this.calculate_charges()
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('membersService', ['create_member_service']),
      ...mapActions('membersInformation', ['search_member']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      searching(value) {
        this.member_id = 0
        this.isLoading = true

        this.search_items = []
        this.search_member({
          search_word: value,
        }).then(response => {
          this.search_items = response.data
          this.search = ''
          this.isLoading = false
        })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.member_id = this.search_items[index].id
          } else {
            this.search = ''
            this.member_id = 0
            this.search_items = []
          }
        } else {
          this.search_items = []
          this.member_id = 0
          this.search = ''
        }
      },
      calculate_charges() {
        if (this.is_member) {
          this.total_amount = parseFloat(this.amount_for_member);
        } else {
          this.total_amount = parseFloat(this.amount_for_non_member);
        }
      },
      save_payments() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true
          //if no error above then proceed
          if (this.is_member) {
            if (this.member_id === 0) {
              this.alert = true
              this.alert_message = 'Please search member first'
              this.saving = false
              proceed = false
            }
          } else {
            if (this.last_name === '' || this.first_name === '' || this.middle_name === '') {
              this.alert = true
              this.alert_message = 'Please Fill up the clients name'
              this.saving = false
              proceed = false
            }
          }
          if (proceed) {
            this.create_member_service({
              branch_id: this.employee_branch_id,
              member_id: this.member_id === 0 ? '' : this.member_id,
              date: this.date,
              type_of_service_id: this.fund_particulars_id,
              type_of_service: this.particulars,
              mode_of_service: this.mode_of_service,
              amount: this.total_amount,
              last_name: this.last_name.toUpperCase(),
              first_name: this.first_name.toUpperCase(),
              middle_name: this.middle_name.toUpperCase(),
            })
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                } else {
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: 'TRANSACTION SUCCESSFULLY',
                  })
                }
                this.saving = false
                this.description = ''
                this.$emit('data', true)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      date_format(value) {
        return moment(value)
      },
    },
  }
</script>
